import { get, post, put, Delete } from "../axios";

// 权益列表-权益分类（用于权益列表查询和新增编辑）
export const equityListClassify = (params) =>
  get("/web/equity/list/classify", params);

// 权益列表-列表
export const equityManageList = (params) =>
  get("/web/equity/manage/list", params);

// 权益列表-新增
export const equityManageAdd = (params) =>
  post("/web/equity/manage/add", params);

// 权益分类-编辑
export const equityManageUpdate = (params) =>
  put("/web/equity/manage/update", params);

// 权益分类-删除
export const equityManageDelete = (id) =>
  Delete("/web/equity/manage/delete/" + id, "");

// 权益列表-详情
export const equityManageView = (id) =>
  get("/web/equity/manage/view/" + id, "");
