<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="dialogTitle"
    width="40%"
    :before-close="handleClose"
  >
    <el-form
      ref="formRef"
      v-loading="formLoading"
      :model="formData"
      :rules="formRules"
      label-width="120px"
    >
      <el-form-item label="权益名称" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item label="权益分类：" prop="classifyIds">
        <el-checkbox-group v-model="formData.classifyIds">
          <el-checkbox
            v-for="item in classifyList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button :disabled="formLoading" type="primary" @click="submitForm"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { Message } from "element-ui";
import {
  equityListClassify,
  equityManageAdd,
  equityManageUpdate,
  equityManageView,
} from "@/api/equity/manage";

export default {
  name: "index",
  props: {
    classifyList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dialogVisible: false, // 弹窗的是否展示
      dialogTitle: "", // 弹窗的标题
      formLoading: false, // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
      formType: "", // 表单的类型：create - 新增；update - 修改
      formData: {
        classifyIds: [],
        name: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        classifyIds: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    // const formRef = ref() // 表单 Ref
    /** 打开弹窗 */
    async open(type, row) {
      this.dialogVisible = true;
      this.dialogTitle = type === "create" ? "新增" : "修改";
      this.formType = type;
      this.resetForm();
      // 修改时，设置数据
      if (row.id) {
        this.formLoading = true;
        try {
          // const { data } = await equityManageView(row.id);
          this.formData = {
            ...row,
            classifyIds: row.classifyIds.split(","),
          };
        } finally {
          this.formLoading = false;
        }
      }
    },
    async submitForm() {
      // 校验表单

      if (!this.$refs.formRef) return;
      // console.log(this.$refs.formRef.$refs.value, "zhe;li");
      const valid = await this.$refs.formRef.validate();

      if (!valid) return;
      // 提交请求
      this.formLoading = true;
      try {
        const selectedItems = this.classifyList.filter((item) =>
          this.formData.classifyIds.includes(item.id)
        );

        // 根据 classifyList 中的 index 字段对选中的项目进行排序
        selectedItems.sort((a, b) => a.index - b.index);
        const data = {
          ...this.formData,
          // classifyIds: this.formData.classifyIds.join(","),
          classifyIds: selectedItems.map((item) => item.id).join(","),
        };

        if (this.formType === "create") {
          await equityManageAdd(data);
          Message.success("新增成功");
        } else {
          await equityManageUpdate(data);
          Message.success("修改成功");
        }
        this.dialogVisible = false;
        // 发送操作成功的事件
        this.$emit("success");
        // setTimeout(() => {
        this.formLoading = false;
        // }, 1000);
      } catch (e) {
        this.formLoading = false;
      } finally {
      }
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.formData.image = [...e];
      } else {
        this.formData.image = [...this.form.image, e];
      }
    },
    // 重置
    resetForm() {
      this.formData = {
        classifyIds: [],
        name: "",
      };
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
