<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >分类名称 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">权益分类 : </span>
              <div class="width300">
                <el-select v-model="searchData.classifyId" clearable>
                  <el-option
                    v-for="item in classifyList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleCreate('create')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="image" slot-scope="{ row }">
        <el-image
          style="width: 80px; height: 80px"
          :src="row.image"
          :preview-src-list="[row.image]"
        >
        </el-image>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <!-- <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
        <el-button type="text" size="mini" @click="handleCreate('update', row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <EquityForm
      ref="formRef"
      @success="handleSearch"
      :classifyList="classifyList"
    />
  </div>
</template>

<script>
import {
  equityListClassify,
  equityManageList,
  equityManageDelete,
} from "@/api/equity/manage";
import { mapGetters } from "vuex";
import EquityForm from "./EquityForm.vue";

export default {
  name: "index",
  components: {
    EquityForm,
  },
  data() {
    return {
      classifyList: [],
      searchData: {
        name: "",
        classifyId: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "权益名称",
            prop: "name",
          },
          {
            label: "分类名称",
            prop: "classifyName",
          },
          // {
          //   label: "所属用户数",
          //   prop: "userNum",
          // },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },
      dialogVisible: false,
    };
  },
  created() {
    this.getClassifyList();
  },
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        // path: this.$route.path,
        // searchData: this.searchData,
        ...this.searchData,
      };
      this.showLoading = true;
      equityManageList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchForm,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 新增编辑
    handleCreate(type, row) {
      this.$refs.formRef.open(type, row);
    },
    // handleCreate(row, type) {
    //   this.$router.push({
    //     path: "/equity/manage/create",
    //     query: {
    //       id: row.id,
    //       type: type,
    //     },
    //   });
    // },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        equityManageDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    //权益分类
    getClassifyList() {
      equityListClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
